<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#139cd7" type="ball-spin-clockwise" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>
<!-- <ngx-spinner color="#139cd7" type="line-scale" [fullScreen]="true" size="medium">
    <p color="#139cd7"> Loading... </p>
</ngx-spinner> -->
<div class="row noMargin create_container">
    <div class="col-4 noPadding flexClass alignSelfCenter">
        <a href="https://apps.bsharpcorp.com/users_dashboard" style="color:#000000">
            <img class="back_arrow alignSelfCenter" src="/assets/task_manager_icons/task_manager_back_arrow.svg" alt="">
        </a>
        <!-- <span class="mainHeading ml-2 alignSelfCenter" *ngIf="!isMobile">Create session</span> -->
        <span class="mainHeading ml-2 alignSelfCenter" *ngIf="!isMobile">Home</span>
    </div>
    <div class="col-8 noPadding flexClass alignSelfCenter" style="justify-content: end;">
         <button class="secondaryBtn mr-2 blue_bg" (click)="downloadSessionData()" *ngIf="!isMobile">Download sessions</button>
         <!--  -->
        <button  *ngIf="admin!='Company Admin'" class="primaryBtn" routerLink="/classroom/create_session/1">Create session</button>
    </div>
    <div class="col-12 noPadding filter_sec normal_mt">
        <form [formGroup]="formGroup" (ngSubmit)="filterSession()">
            <div class="row noMargin filter_head">
                <div class="col-6 col-md-8 noPadding alignSelfCenter">
                    <span class="alignSelfCenter subHeading half_mr alignSelfCenter">All Sessions</span>
                    <span class="count_badge alignSelfCenter">{{totalCount}}</span>
                </div>
                <!-- <div class="col-6 col-md-4 noPadding text-right alignSelfCenter">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                <i class="fa fa-search"></i>
                            </span>
                        </div>
                        <input type="text" class="form-control primaryText" placeholder="Search">
                    </div>
                </div> -->
            </div>
            <div class="row noMargin filter_body">
                <div class="filters">
                    <!-- <div class="form-group noMargin half_pr mob_width"> -->
                        <!-- <div class="small_device_margin input-group input_noBorder calendar_grp input_cust alignSelfCenter noMargin half_pr mob_width">
                            <button class="btn btn-outline-secondary calendar_btn flexClass ht_class" (click)="d.toggle()" type="button">
                                <i class="fa fa-calendar alignSelfCenter"></i>
                            </button>
                            <input
                                class="form-control ht_class date_input"
                                name="dp"
                                placeholder="Session date"
                                formControlName="date"
                                ngbDatepicker
                                #d="ngbDatepicker"
                                [(ngModel)]="model"
                            />

                        </div> -->
                        <div class="small_device_margin input-group input_noBorder calendar_grp input_cust alignSelfCenter noMargin half_pr mob_width">
                            <button class="btn btn-outline-secondary calendar_btn flexClass ht_class" (click)="d.toggle()" type="button">
                                <i class="fa fa-calendar alignSelfCenter"></i>
                            </button>
                            <input
                                class="form-control ht_class date_input"
                                name="dp"
                                placeholder="From date"
                                formControlName="from_date"
                                ngbDatepicker
                                #d="ngbDatepicker"
                                [(ngModel)]="from_date"
                            />
                        </div>
    
                        <div class="small_device_margin input-group input_noBorder calendar_grp input_cust alignSelfCenter noMargin half_pr mob_width">
                            <button class="btn btn-outline-secondary calendar_btn flexClass ht_class" (click)="d1.toggle()" type="button">
                                <i class="fa fa-calendar alignSelfCenter"></i>
                            </button>
                            <input
                                class="form-control ht_class date_input"
                                name="dp"
                                placeholder="To date"
                                formControlName="to_date"
                                ngbDatepicker
                                #d1="ngbDatepicker"
                                [(ngModel)]="to_date"
                            />
                        </div>
                        <!-- <igx-date-picker class="input_border input_cust small_device_margin" formControlName="date"
                            mode="dropdown">
                            <label igxLabel>Session date</label>
                        </igx-date-picker> -->
                    <!-- </div> -->
                    <div class="form-group noMargin half_pr small_device_margin mob_width flexClass">
                        <angular2-multiselect [data]="moduleNamesList" formControlName="module"
                            [settings]="moduleDropdownSettings" ></angular2-multiselect>
                    </div>
                    <div class="form-group noMargin half_pr small_device_margin mob_width status_drop">
                        <igx-select class="input_border primaryText input_cust" formControlName="type"
                            placeholder="Select">
                            <label igxLabel>Filter by type</label>
                            <igx-select-item value="0">Online</igx-select-item>
                            <igx-select-item value="1">Offline</igx-select-item>
                            <igx-select-item value="2">Online & Offline</igx-select-item>
                        </igx-select>
                    </div>
                    <div class="form-group noMargin half_pr small_device_margin mob_width status_drop">
                        <igx-select class="input_border primaryText input_cust" formControlName="status"
                            placeholder="Select">
                            <label igxLabel>Filter by status</label>
                            <igx-select-item value="0">Upcoming</igx-select-item>
                            <igx-select-item value="1">Completed</igx-select-item>
                            <igx-select-item value="2">Cancelled</igx-select-item>
                        </igx-select>
                    </div>
                    <div *ngIf="admin=='Company Admin'" class="form-group noMargin half_pr small_device_margin mob_width flexClass">
                        <angular2-multiselect [data]='rtmTrainerList' formControlName="trainer"
                            [settings]="trainerDropdownSettings"></angular2-multiselect>
                    </div>
                    <div class="form-group noMargin half_pr small_device_margin mob_width" *ngIf="!isDtForApply">
                        <button *ngIf="isFilterApplied" type="button" (click)="resetBtnClick()" class="resetBtn mr-2 alignSelfCenter primaryText">Reset</button>
                        <button class="secondaryBtn mr-2 blue_bg alignSelfCenter">Apply</button>
                    </div>

                </div>
                <div class="button flexClass alignSelfCenter" *ngIf="isDtForApply">
                    <button *ngIf="isFilterApplied" type="button" (click)="resetBtnClick()" class="resetBtn mr-2 alignSelfCenter primaryText">Reset</button>
                    <button class="secondaryBtn mr-2 blue_bg alignSelfCenter trainer_list">Apply</button>
                </div>

            </div>
        </form>
    </div>
    <div class="col-12 noPadding list_sec">
        <div class="table-wrapper" *ngIf="tableData.length != 0">
            <div class="table noMargin">
                <div class="row noMargin table_row">
                    <div class="cell div_flex">
                        <div class="row">
                            <div class="cell date_flex primaryText">Schedule</div>
                            <div class="cell main_flex primaryText">Classroom session</div>
                            <div class="cell sml_flex primaryText" *ngIf="!isMobile">Trainer Name</div>
                            <!-- <div class="cell sml_flex primaryText" *ngIf="!isMobile">Enrolled</div> -->
                            <div class="cell sml_flex primaryText" *ngIf="!isMobile">Attendance</div>
                            <div class="cell status_flex primaryText" *ngIf="!isMobile">Status</div>
                        </div>
                    </div>
                    <div class="cell report_flex primaryText" *ngIf="!isMobile">Report</div>
                </div>
                <div class="row noMargin" *ngFor="let item of tableData |paginate: {itemsPerPage: itemsPerPage,
                    currentPage: currentPage, totalItems: totalCount }; let i = index">
                    <div class="col-12 noPadding">
                        <div class="row noMargin table_section" *ngIf="i == 0 && item.length != 0">Upcoming</div>
                        <div class="row noMargin table_section" *ngIf="i == 1 && item.length != 0">Closed</div>
                        <div class="row noMargin table_body_row pointer" *ngFor="let data of item">
                            <!-- routerLink="/classroom/view_session/{{data.sid}}" -->
                            <div class="cell div_flex" (click)="redirect(data.sid)">
                                <div class="row">
                                    <div class="cell date_flex">
                                        <app-date-chip *ngIf="!isMobile" [dateData]="data.time_data"></app-date-chip>
                                        <div class="subHeading" *ngIf="isMobile">
                                            {{(data.time_data.session_date * 1000) | date:'dd'}} {{(data.time_data.session_date * 1000) |
                                            date:'MMM'}}
                                        </div>
                                        <div class="smallText" *ngIf="isMobile">{{(data.time_data.session_date * 1000) | date:'EEE'}}
                                        </div>
                                        <div class="primaryText" *ngIf="isMobile">
                                            {{data.time_data.from_time | date:'h:mm a'}} - {{data.time_data.to_time | date:'h:mm
                                            a'}}
                                        </div>
                                        <div class="smallText" *ngIf="isMobile">{{loggedInTimeZone}}</div>
                                    </div>
                                    <div class="cell main_flex truncate">
                                        <div class="row noMargin">
                                            <span class="primaryText session_title truncate">{{data.module_name}}</span>
                                        </div>
                                        <div class="row noMargin primaryText session_details truncate">
                                            <span class="onNoff_chip common_chip alignSelfCenter"
                                                *ngIf="data.classroom_type == 2">Online & offline</span>
                                            <span class="online_chip common_chip alignSelfCenter"
                                                *ngIf="data.classroom_type == 0">Online</span>
                                            <span class="offline_chip common_chip alignSelfCenter"
                                                *ngIf="data.classroom_type == 1">Offline</span>
                                            <span class="skill_type alignSelfCenter col noPadding truncate" *ngIf="data.session_type == 1">
                                                {{data.session_name}}
                                            </span>
                                        </div>
                                        <div class="row noMargin primaryText session_details">
                                            <span class="smallText alignSelfCenter mr-2" *ngIf="isMobile">Attendance:
                                                {{data.attendance_count}}</span>
                                            <span class="smallText alignSelfCenter" *ngIf="isMobile">
                                                <span *ngIf="data.status == 1">Status: Completed</span>
                                                <span *ngIf="data.status == 0" class="orange_text">Status: Open</span>
                                                <span *ngIf="data.status == 2" class="red_text">Status: Cancelled</span>
                                                <span *ngIf="data.status == 3" class="orange_text">Status: In Progress</span>
                                                <span *ngIf="data.status == 4" class="red_text">Status: Expired</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="cell sml_flex primaryText"*ngIf="!isMobile">
                                        {{data.lc_name}}
                                    </div>
                                    
                                    <div class="cell sml_flex primaryText" *ngIf="!isMobile">{{data.attendance_count}}</div>
                                    <div class="cell status_flex primaryText" *ngIf="!isMobile">
                                        <span *ngIf="data.status == 1">Completed</span>
                                        <span *ngIf="data.status == 0" class="orange_text">Open</span>
                                        <span *ngIf="data.status == 2" class="red_text">Cancelled</span>
                                        <span *ngIf="data.status == 3" class="orange_text">In Progress</span>
                                        <span *ngIf="data.status == 4" class="orange_text">Expired</span>
                                    </div>
                                </div>
                            </div>
                            <div class="cell report_flex primaryText" *ngIf="!isMobile">
                                <div class="row">
                                    <div class="module cell text-center assigned_flex  activeMod">
                                        <img  data-toggle="tooltip" title="Detailed report" (click)="toggleActiveModule('module',data.sid)" width="24" height="24" style="cursor: pointer !important;" src="assets/classroom/classroom_icons/module.svg" class="report_icon" />
                                        <img data-toggle="tooltip" title="Session report" (click)="toggleActiveModule('session',data.sid)" width="24" height="24" style="cursor: pointer !important;" src="assets/classroom/classroom_icons/session.svg"  />
                                    </div>
                                    
                                </div>
                                
                            </div>
                        </div> 
                    </div>
                </div>

            </div>
            <div class="col-12 noPadding text-center">
                <pagination-controls class="my-pagination normal_mt" responsive="true" previousLabel=" " nextLabel=" "
                    (pageChange)="pageChange($event)" autoHide="true" directionLinks="true" maxSize="9"
                    [(currentPage)]="currentPage" [itemsPerPage]="itemsPerPage" [totalItems]="totalCount">
                </pagination-controls>
            </div>
        </div>
        <div class="default_img_sec col-12 noPadding text-center flexClass" *ngIf="tableData.length == 0">
            <div class="inner_class alignSelfCenter mx-auto">
                <img class="default_img_attendence" src="assets/classroom/images/user_session_default.png" alt="">
                <div class="default_text subHeading mt-2">
                    No classroom session is in progress or scheduled yet
                </div>
            </div>

        </div>
    </div>
</div>
