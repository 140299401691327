import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { baseUrl } from 'src/app/config/api';
import { CookieService } from 'ngx-cookie-service';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';

@Injectable({
    providedIn: 'root'
})

export class InfogeonLearningChampionService { 
    constructor(private http: HttpClient, private cookieService: CookieService) { }

    httpOptions = {

        headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-CSRFToken': this.cookieService.get('csrftoken'),
        }),
        withCredentials: true
    };

    lc_topic_list(): Observable<any> {
        return this.http.get(baseUrl + '/learning_champion/lc_topic_list', this.httpOptions);
    }
    get_lc_store_users(session_id):Observable<any> { 
        return this.http.get(baseUrl + '/learning_champion/lc_store_code_users/'+session_id, this.httpOptions);
    }
    add_lc_session_users(data): Observable<any> {
        return this.http.post(baseUrl + '/learning_champion/add_lc_session_users', data, this.httpOptions);
    }
    // get_all_session_list(data):Observable<any> {
    //     return this.http.post(baseUrl + '/learning_champion/lc_session_list', data, this.httpOptions);
    // }
    lc_full_session_list(data):Observable<any> {
        return this.http.post(baseUrl + '/learning_champion/lc_full_session_list', data, this.httpOptions);
    }
    add_lc_unregistered_users(data):Observable<any> {
        return this.http.post(baseUrl + '/learning_champion/add_lc_unregistered_users', data, this.httpOptions);
    }
    // get_all_unregistered_users():Observable<any> {
    //     return this.http.get(baseUrl + '/learning_champion/get_all_unregistered_users',this.httpOptions);
    // }

    get_lc_individual_session_report(sid): Observable<any> {
        return this.http.get(baseUrl + '/learning_champion/get_lc_individual_session_report/'+sid, this.httpOptions);
    }

    get_lc_trainers_list():Observable<any> {
        return this.http.get(baseUrl + '/learning_champion/get_lc_trainers_list', this.httpOptions);
    }


}
