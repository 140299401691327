<div class="row noMargin create_container">
    <div class="col-12 col-sm-11 col-md-10 col-lg-9 col-xl-8 noPadding flexClass mx-auto">
        <a href="/classroom/session_list" style="color:#000000">
<!--        <a href="https://qa-infogeon.bsharp.in/users_dashboard" style="color:#000000">-->
            <img class="back_arrow alignSelfCenter" src="/assets/task_manager_icons/task_manager_back_arrow.svg" alt="">
        </a>
        <span class="mainHeading ml-2 alignSelfCenter">Home</span>
    </div>
    <form class="col-12 col-sm-11 col-md-10 col-lg-9 col-xl-8 noPadding mx-auto" [formGroup]="formGroup"
        (ngSubmit)="createSession()">
        <div class="row noMargin">
            <div class="col-12 noPadding white_sec normal_mt">
                <div class="row noMargin body_sec">
                    <div class="col-12 col-sm-11 col-md-10 col-lg-9 col-xl-8 mx-auto noPadding">
                        <!-- <div class="row noMargin">
                            <igx-radio-group name="session_type" formControlName="selected">
                                <igx-radio class="radio_cust left_radio" value="instant" [checked]="inst_session_show">Instant
                                    session</igx-radio>
                                <igx-radio class="radio_cust" value="schedule" [checked]="schedule_session_show">Schedule session</igx-radio>
                            </igx-radio-group>
                        </div> -->
                        <div class="row noMargin module_sec normal_mt">
                            <div class="col-12 input_head primaryText">Module</div>
                            <div class="col-12 input_body noPadding">
                                <div class="form-group noMargin">
                                    <angular2-multiselect [data]="modDropdownList" [(ngModel)]="selectedModules"
                                        formControlName="accountSelect"
                                        [settings]="modDropdownSettings" (ngModelChange)="onModuleSelect($event)"></angular2-multiselect>
                                </div>
                            </div>
                        </div>
                        
                        <!-- new sections -->
                        <div class="row noMargin module_sec normal_mt">
                            <div class="col-12 input_head primaryText">Category</div>
                            <div class="col-12 input_body noPadding">
                                <div class="form-group noMargin">
                                    <angular2-multiselect [data]="skillsDropdownList" [(ngModel)]="selectedSkills"
                                        formControlName="skillsSelect"
                                        [settings]="skillsDropdownSettings" (ngModelChange)="onSkillSelect($event)"></angular2-multiselect>
                                </div>
                            </div>
                        </div>
                        <div class="row noMargin module_sec normal_mt">
                            <div class="col-12 input_head primaryText">Sub Category</div>
                            <div class="col-12 input_body noPadding">
                                <div class="form-group noMargin">
                                    <angular2-multiselect [data]="subSkillsDropdownList" [(ngModel)]="selectedSubSkills"
                                        formControlName="subSkillSelect"
                                        [settings]="subSkillsDropdownSettings"></angular2-multiselect>
                                </div>
                            </div>
                        </div>
                        <div class="row noMargin module_sec normal_mt">
                            <div class="col-12 input_head primaryText">Session name</div>
                            <div class="col-12 input_body noPadding">
                                <div class="form-group noMargin">
                                    <igx-input-group class="input_border primaryText" type="box">
                                        <input igxInput name="session_name" formControlName="session_name"
                                            type="text" />
                                        <label igxLabel for="session_name">Type here...</label>
                                    </igx-input-group>
                                </div>
                            </div>
                        </div>

                        <div class="row noMargin module_sec normal_mt">
                            <div class="col-12 input_head primaryText">Trainer Type</div>
                            <div class="col-12 input_body noPadding">
                                <div class="form-group noMargin">
                                    <angular2-multiselect [data]="trainer_type" [(ngModel)]="selectedTrainerType"
                                        formControlName="trainerType"
                                        [settings]="trainerDropdownSettings"></angular2-multiselect>
                                </div>
                            </div>
                        </div>

                        <div class="row noMargin module_sec normal_mt">
                            <div class="col-12 input_head">
                                <div class="row noMargin">
                                    <div class="col-4 primaryText noPadding">Date and time</div>
                                    <div class="col-8 noPadding smallText flexClass" style="justify-content: end;">
                                        <span class="alignSelfCenter timezone_text">{{timezone}}</span>
                                        <span class="alignSelfCenter change_span ml-2 pointer"
                                            (click)="timezone_change(userModel)">Change</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 input_body noPadding">
                                <div class="row noMargin time_date_row">
                                    <div class="form-group noMargin col-md-4 col-sm-4 col-12 flexClass">
                                        <div class="input-group input_noBorder calendar_grp input_cust alignSelfCenter">
                                            <button class="btn btn-outline-secondary calendar_btn flexClass" (click)="d.toggle()" type="button">
                                                <i class="fa fa-calendar alignSelfCenter"></i>
                                            </button>
                                            <input
                                                class="form-control"
                                                name="dp"
                                                [minDate]="minDate"
                                                formControlName="session_date"
                                                ngbDatepicker
                                                #d="ngbDatepicker"
                                                [(ngModel)]="model"
                                                (dateSelect)="onDateSelect()"
                                            />

                                        </div>
                                    </div>
                                    <div class="form-group noMargin col-md-4 col-sm-4 col-6">
                                        <igx-time-picker class="input_noBorder input_cust" formControlName="from_time"
                                            mode="dropdown">
                                            <label igxLabel>From</label>
                                        </igx-time-picker>
                                    </div>
                                    <div class="form-group noMargin col-md-4 col-sm-4 col-6">
                                        <igx-time-picker class="input_noBorder input_cust" formControlName="to_time"
                                            mode="dropdown">
                                            <label igxLabel>To </label>
                                        </igx-time-picker>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row noMargin normal_mt">
                            <div class="col-12 noPadding">
                                <div class="row noMargin">
                                    <igx-radio-group name="classroomType" formControlName="classroom_type"
                                        (change)="onClsTypeChange($event)">
                                        <div class="primaryText alignSelfCenter left_radio">Classroom type:</div>
                                        <igx-radio class="radio_cust left_radio alignSelfCenter"
                                            value="0">Online</igx-radio>
                                        <igx-radio class="radio_cust left_radio alignSelfCenter"
                                            value="1">Offline</igx-radio>
                                        <igx-radio class="radio_cust alignSelfCenter" value="2">Online &
                                            offline</igx-radio>
                                    </igx-radio-group>
                                </div>
                            </div>
                        </div>
                        <div class="row noMargin normal_mb" *ngIf="isSessionSelected">
                            <div class="col-12 noPadding">
                                <div class="row noMargin module_sec normal_mb"
                                    *ngIf="selectedClassRoomType == 0 || selectedClassRoomType == 2">
                                    <div class="col-12 input_head primaryText">Online</div>
                                    <div class="col-12 input_body noPadding">
                                        <div class="form-group noMargin">
                                            <igx-input-group class="input_border primaryText" type="box">
                                                <input igxInput name="meeting_link" formControlName="meeting_link"
                                                    type="text" />
                                                <label igxLabel for="meeting_link">Meeting link here....</label>
                                            </igx-input-group>
                                        </div>
                                    </div>
                                </div>
                                <div class="row noMargin module_sec normal_mb"
                                    *ngIf="selectedClassRoomType == 1 || selectedClassRoomType == 2">
                                    <div class="col-12 input_head primaryText">Offline</div>
                                    <div class="col-12 input_body noPadding">
                                        <div class="form-group noMargin">
                                            <igx-input-group class="input_border top_input primaryText" type="box">
                                                <input igxInput name="address" formControlName="address" type="text" />
                                                <label igxLabel for="address">Address</label>
                                            </igx-input-group>
                                            <igx-input-group class="input_border bottom_input primaryText" type="box">
                                                <input igxInput name="geolocation" formControlName="geolocation"
                                                    type="text" />
                                                <label igxLabel for="geolocation">Geo-location</label>
                                            </igx-input-group>
                                        </div>
                                    </div>
                                </div>
                                
                                <!-- <div class="row noMargin primaryText normal_mt">Assign users</div> -->
                                <!-- <div class="row noMargin mt-1"> -->
                                    <!-- <div class="col-12 input_body noPadding"> -->
                                        <!-- <div class="form-group noMargin"> -->
                                            <!-- <button type = "button" class="primaryBtn blue_bg add_user_btn flexClass" (click)="createUserModel(userAttributeModel)">
                                                <span class="alignSelfCenter">Add users</span>
                                            </button> -->
                                            <!-- <ng-template #userAttributeModel let-modal> -->
                                                    <!-- Modal body -->
                                                    <!-- <div class="modal-body row noMargin noPadding">
                                                        <div class="col-12 modal_body">
                                                            <div class="row noMargin primaryText mb-1 attribut_margin_one">Select user group by attributes</div>
                                                            <div class="row noMargin primaryText mb-2 attribut_margin_one">
                                                                <angular2-multiselect [data]="attribute" [(ngModel)]="selectedAttribute" 
                                                                    formControlName="assign_attribute"
                                                                    [settings]="attributeDropdownSettings" (onSelect)="onSelectedChange($event)"></angular2-multiselect>
                                                            </div>
                                                            <div class="row noMargin primaryText mb-2 attribut_margin" *ngIf = "isAttributeSelect && isAttributeMultiSelect">
                                                                <angular2-multiselect [data]="attributeOptions" [(ngModel)]="selectedAttributeOption"
                                                                formControlName="assign_attribute_option"
                                                                    [settings]="attributeOptionDropdownSettings"></angular2-multiselect>
                                                            </div>
                                                            <div class="row noMargin primaryText mb-2 attribut_margin select_user_attri_option" *ngIf = "isAttributeSelect && isAttributeNoOption == true">
                                                                <input type = "text" [(ngModel)]="selectedAttributeText" class="primaryText form-control"
                                                                formControlName="assign_attribute_text" placeholder="Enter here">
                                                            </div>
                                                        
                                                        </div>
                                                        <div class="col-12 modal_footer text-right">
                                                            <button (click)="get_users()" class="primaryBtn">Save</button>
                                                        </div>
                                                    </div> -->
                                                
                                            <!-- </ng-template> -->
                                            <!-- <angular2-multiselect [data]="groupDropdownList"
                                                [(ngModel)]="selectedGroups" (onSelect)="onFirstDropdownChange($event)"
                                                formControlName="assign_gid"
                                                [settings]="groupDropdownSettings"></angular2-multiselect> -->
                                        <!-- </div> -->
                                    <!-- </div> -->
                                <!-- </div> -->
                                <!-- <div class="row noMargin module_sec normal_mt">
                                    <div class="col-12 input_head primaryText">Individual</div>
                                    <div class="col-12 input_body noPadding">
                                        <div class="form-group noMargin">
                                            <angular2-multiselect [data]="individualsList"
                                                [(ngModel)]="selectedIndividuals" formControlName="selectUsers"
                                                [settings]="gIndDropdownSettings"></angular2-multiselect>
                                        </div>
                                    </div>
                                </div> -->
                                <!-- <div class="row noMargin smallText info_text">
                                    Note: You can only select the users from the selected group above.
                                </div> -->
                                <!-- <div class="row noMargin">
                                    <div class="toggle_switch flexClass normal_mt">
                                        <button class="inactive_btn common_toggle" *ngIf="!enrollEnable"
                                            (click)="enableEnroll()">Auto-enrollment</button>
                                        <button class="active_btn common_toggle mr-2"
                                            *ngIf="enrollEnable">Auto-enrollment</button>
                                        <button class="inactive_btn common_toggle" *ngIf="enrollEnable"
                                            (click)="disableEnroll()">Allow user to enroll</button>
                                        <button class="active_btn common_toggle" *ngIf="!enrollEnable">Allow user to
                                            enroll</button>
                                    </div>
                                </div> -->
                                <!-- <div class="row noMargin smallText info_text lh_med">
                                    (The ‘Auto-enroll’ feature will mandate the session and eliminate the alternative
                                    for
                                    users to enroll at their discretion.)
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row noMargin footer_sec">

                  <a href="https://todo.bsharpcorp.com/classroom/session_list">
<!--                  <a href="https://calendar.bsharp.in/classroom/session_list">-->
                     <button type="button" class="secondaryBtn left_radio">Cancel</button>
                  </a>

                    <button type="submit" class="primaryBtn">Add Participants</button>
                </div>
            </div>
        </div>
    </form>



    <ng-template #userModel let-modal>

        <!-- Modal body -->
        <div class="modal-body row noMargin noPadding">
            <div class="col-12 modal_body">
                <div class="row noMargin primaryText mb-1">Timezones</div>
                <div class="row noMargin primaryText mb-1" class="width100">
                    <!--                    <angular2-multiselect [data]="userList" [(ngModel)]="selectedUsers"-->
                    <!--                                            [settings]="DropdownSettings"></angular2-multiselect>-->

                    <angular2-multiselect [data]="timeZoneList" [(ngModel)]="selectedTimezone"
                        (onSelect)="onSelectedValueChange($event)" [settings]="dropdownSettings">
                    </angular2-multiselect>
                </div>

            </div>
            <div class="col-12 modal_footer text-right">
                <button (click)="closeModal()" class="primaryBtn">close</button>
            </div>
        </div>

    </ng-template>

</div>
